.Profile {
  padding: 24px;
  background: #fff;
  margin-bottom: 24px;
}

.ChangePassword {
  padding: 24px;
  background: #fff;
}
