.LoginForm {
    margin-left: auto;
    margin-right: auto;
    padding: 150px 10px 0px 10px;
    width:30%;
}

@media screen and (max-width: 600px){
    .LoginForm{
        width: auto;
    }
}
.LoginForm .ant-input-prefix{
    color: #1890FF;
}