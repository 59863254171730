.logo {
    height: 64px;
    background-color: #ffffff;
}

.logo-img {
    height: 64px;
    padding: 8px;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: #fff;
}

.ant-drawer-header{
    display: none;
}

.sider-desktop {
    display: block;
}

.ant-btn.buttonlayoutpage {
    display: none;
}

@media only screen and (max-width: 992px) {
    .sider-desktop {
        display: none;
    }
  }
  
.sider-mobile {
    display: none;
}
@media only screen and (max-width: 992px) {
    .sider-mobile {
        display: block;
        overflow: none;
    }
    .ant-btn.buttonlayoutpage {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}