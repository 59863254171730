.ant-message-custom-content {
    text-align: left;
}

.ant-message-notice-content {
    padding: 0 !important;
    min-width: 25% !important;
    max-width: calc(100vw - 20px) !important;
}

.ant-message-custom-content > span.anticon {
    display: none;
}

.ant-alert-close-icon > span.anticon {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
}
