.InvitationForm {
    margin-left: auto;
    margin-right: auto;
    padding: 90px 10px 0px 10px;
    width:30%;
}

@media screen and (max-width: 600px){
    .InvitationForm{
        width: auto;
    }
}