body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

*::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.adminpage .ant-form-item-control-input-content{
  max-width: 389px !important;
}

@media only screen and (min-width: 700px) {
  .FormCustom {
    margin-left: 34%;
  }
  .FormCustom .ant-form-item-label{
    text-align: start;
    min-width: 90px;
  }
}

.tableUser .ant-table table{
  table-layout: fixed !important;
}

.ant-tag{
  margin: 4px 4px 4px 4px;
  color: #7C87A0;
}

.hoverIcon{
  font-size: 24px !important;
  filter: brightness(0) !important;
  opacity: 0.8 !important;
  margin: -4px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .hoverIcon{
  filter: brightness(1) !important;
}

.ant-menu-item:hover .hoverIcon{
  filter: brightness(1) !important;
}

.ant-badge-status-text{
  margin-left: 5px;
}

.ant-badge-status-success{
  background-color: #059669;
}

.ant-badge-status-default{
  background-color: #3C688C;
}

.ant-badge-status-warning{
  background-color: #D97706;
}

.ant-badge-status-error{
  background-color: #E11D48;
}

.status-sent{
  border: 1px solid #D1FAE5;
  border-radius: 16px;
  padding: 2px 8px 2px 8px;
  background: #ECFDF5;
}

.status-draft{
  border: 1px solid #C0D7EA;
  border-radius: 16px;
  padding: 2px 8px 2px 8px;
  background: #E6F1F9;
}

.status-schedule{
  border: 1px solid #FEF3C7;
  border-radius: 16px;
  padding: 2px 8px 2px 8px;
  background: #FFFBEB;
}

.status-failed{
  border: 1px solid #FFE4E6;
  border-radius: 16px;
  padding: 2px 8px 2px 8px;
  background: #FFF1F2;
}

.action-active{
  font-size: 32px;
  font-weight: 700;
  color:#666666;
  padding: 5px 6px;
  border-radius: 50%;
}

.action-active:focus { 
  background-color: #DEDEDE;
  padding: 5px 6px;
  border-radius: 50%;
}

.ant-form-item{
  margin-bottom: 4px;
}

.ant-space-item{
  width: 100%;
}

.textAreaBroadcast textarea{
  resize: none !important;
  height: 150px !important;
  padding: 15px;
}

.textAreaBroadcast2 textarea{
  resize: none !important;
  height: 30px !important;}

.ant-picker{
  width: 100%;
}

.formSearch .ant-form-item-label{
  max-width: 100%;
}

.ant-modal-mask{
  background-color: rgba(0, 0, 0, 0.8);
}

.createBroadcast .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  content: none;
}

.createBroadcast .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after{
  content: "*";
  color: #F04438;
  font-size: 16px;
}

.createBroadcast .ant-form-item-control{
  display: inline;
}

.ant-form-item-label > label::after{
  content: ":";
}

.ant-form-item-has-error .ant-input-textarea-show-count::after{
  color: #F04438 !important;
}

.card-count .ant-card-head{
  border: none;
  background-color: #EEEEEE;
}

.card-count .ant-card-head-title{
  color: #282828;
  font-size: 24px;
}

.table-bc .ant-table-empty .ant-table-tbody > tr > td{
  font-size: 26px;
}

.table-bc .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder{
  height: 300px;
}

.show-form{
  display: none !important;
  visibility: hidden !important;
}

.tag-view{
  background-color: #7C87A0;
  border-radius: 30px;
  padding: 3px 8px 3px 8px;
  font-size: 14px;
  color: #FFF;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
  padding: 10px 16px;
}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
  font-size: 16px;
}

.ant-form-item-label > label{
  font-size: 16px;
}

.ant-table{
  font-size: 14px;
}

.ant-typography{
  color: #000 !important;
}

.ant-modal-body{
  font-size: 16px;
  font-weight: 400;
}

body{
  font-family: "kanit";
}

.hide-Validator .ant-form-item-control-input{
  min-height: 0 !important;
}

h4.ant-typography, .ant-typography h4{
  text-transform: capitalize !important;
}